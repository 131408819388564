import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import media, { legacyBreakpoints } from '../utils/media'
import CTA from './CTA'
import PageBannerWrapper from './PageBannerWrapper'
import Wrapper from './Wrapper'

const Container = styled(Wrapper)`
  padding: 75px 20px 0 20px;
  margin-bottom: 50px;
  text-align: center;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 35px 20px 0 20px;

    p {
      margin-bottom: 20px;
    }
  }
`

const ProductContainer = styled.div`
  position: relative;
  max-width: 776px;
  margin: 50px auto 0 auto;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    margin: 35px 0 0 0;
  }
`

const Character = styled.div`
  position: absolute;
  width: 40%;
  bottom: -50px;
`

const LeftCharacter = styled(Character)`
  left: -15%;
`

const RightCharacter = styled(Character)`
  right: 0;
  width: 29%;

  ${media.lg`
    right: -5%;
    width: 30%;
  `}
`

const PageBannerProductShot = ({ title, description, cta, productImage }) => {
  return (
    <PageBannerWrapper style={{ paddingBottom: 0 }}>
      {title && (
        <Container>
          <h1>{title}</h1>
          {description && <p>{description}</p>}
          {cta && (
            <CTA
              $primary
              to={cta}>
              {cta.text.value}
            </CTA>
          )}
          <ProductContainer>
            {productImage && (
              <GatsbyImage
                image={productImage}
                alt={title}
                loading="eager"
              />
            )}
            <LeftCharacter>
              <StaticImage
                src="../assets/img/landing-banner-left-char.png"
                width={310}
                alt=""
                loading="eager"
              />
            </LeftCharacter>
            <RightCharacter>
              <StaticImage
                src="../assets/img/landing-banner-right-char.png"
                width={233}
                alt=""
                loading="eager"
              />
            </RightCharacter>
          </ProductContainer>
        </Container>
      )}
    </PageBannerWrapper>
  )
}

export default PageBannerProductShot
